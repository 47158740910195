import type { HotelCoordinate } from '@dx-ui/queries-dx-shop-search-ui';

type Bounds =
  | {
      northeast: Pick<HotelCoordinate, 'latitude' | 'longitude'>;
      southwest: Pick<HotelCoordinate, 'latitude' | 'longitude'>;
    }
  | null
  | undefined;

const transformBounds = (bounds: Bounds) => {
  if (!bounds) {
    return null;
  }

  return {
    northeast: {
      latitude: bounds?.northeast?.latitude || 0,
      longitude: bounds?.northeast?.longitude || 0,
    },
    southwest: {
      latitude: bounds?.southwest?.latitude || 0,
      longitude: bounds?.southwest?.longitude || 0,
    },
  };
};
export const returnInitialBounds = (
  hotelSummaryOptionsBounds: Bounds,
  matchBounds: Bounds,
  boundsFromParamCoords?: Bounds
) => {
  // In priority usage order
  const initialBounds =
    transformBounds(boundsFromParamCoords) ||
    transformBounds(hotelSummaryOptionsBounds) ||
    transformBounds(matchBounds) ||
    null;
  return {
    south: initialBounds?.southwest.latitude || 0,
    west: initialBounds?.southwest.longitude || 0,
    north: initialBounds?.northeast.latitude || 0,
    east: initialBounds?.northeast.longitude || 0,
  };
};

export const getMapMarkerIcon = (isActive: boolean, disableMarkerPrice: boolean) => {
  //active
  if (isActive) return '/assets/map-pin-alt-active.png';
  // greater than 150 properties search result
  if (disableMarkerPrice) return '/assets/map-pin.png';
  //unavailable
  return '/assets/map-pin-alt.png';
};

export const getMarkerPrice = (
  disableMarkerPrice: boolean,
  usePoints: boolean,
  points?: string | null,
  price?: string | null
) => {
  if (!disableMarkerPrice && usePoints && points) return points;
  if (!disableMarkerPrice && price) return price;
  return undefined;
};

export const updateMapBounds = ({
  mapBounds,
  newBounds,
  setMapBounds,
  setMapCenter,
  onBoundsChange,
}: {
  mapBounds: google.maps.LatLngBoundsLiteral;
  newBounds?: google.maps.LatLngBoundsLiteral;
  setMapBounds: (bounds: google.maps.LatLngBoundsLiteral) => void;
  setMapCenter: (center: google.maps.LatLngLiteral) => void;
  onBoundsChange?: () => void;
}): void => {
  if (newBounds && JSON.stringify(mapBounds) !== JSON.stringify(newBounds)) {
    setMapBounds(newBounds);
    if (
      newBounds?.south !== undefined &&
      newBounds?.north !== undefined &&
      newBounds?.west !== undefined &&
      newBounds?.east !== undefined
    ) {
      setMapCenter({
        lat: (newBounds.south + newBounds.north) / 2,
        lng: (newBounds.west + newBounds.east) / 2,
      });
    }
    if (onBoundsChange) {
      onBoundsChange();
    }
  }
};
