import type {
  Adult,
  CBPRoom,
  Child,
  MapLanguage,
  Rates,
  BookRates,
} from '@dx-ui/framework-uri-builder';
import { bookUriBuilder } from '@dx-ui/framework-uri-builder';
import { parseISO, addDays } from 'date-fns';
import type { QueryParameters } from '../../constants';
import type { ShopPropStatusType } from '@dx-ui/queries-dx-shop-search-ui';

export type AvailStatus = ShopPropStatusType | 'LOADING' | 'VIEW_AVAILABLE_DATES';

export type GetBookURLParamsType = {
  additionalQS: { [key: string]: string };
  page: 'flexibledates' | 'deeplink' | undefined;
  arrivalDate?: string;
  departureDate?: string;
  rates?: Rates;
  roomInfo?: CBPRoom[];
  shouldExcludeSMBRateParameter?: boolean;
};

const getAdditionalMarketingParams = (
  currencyCode: string,
  usePoints?: boolean,
  queryParametersDream?: QueryParameters
): GetBookURLParamsType => {
  const { redeemPts, cid } = queryParametersDream || {};

  const additionalQS: { [key: string]: string } = {};

  if (currencyCode) additionalQS.displayCurrency = currencyCode;
  if (redeemPts || usePoints) additionalQS.redeemPts = `${redeemPts || usePoints}`;
  if (cid) additionalQS.cid = cid;

  return {
    additionalQS,
    page: 'flexibledates',
  };
};

export const getRatesRoomInfoAdditionalQS = (
  availStatus: AvailStatus | null,
  queryParameters: QueryParameters,
  currencyCode: string,
  usePointsExplorer: boolean,
  usePoints = false
): GetBookURLParamsType => {
  const {
    datesFlex,
    travelAgentRate,
    token,
    promoCode,
    corporateCode,
    groupCode,
    employeeRate,
    ownerVIPRate,
    ownerHGVRate,
    friendsAndFamilyRate,
    rooms,
    arrivalDate,
    departureDate,
    specialRateTokens,
    pnd,
    offerId,
    specPlan,
    travelAgentId,
    fromId,
    requestedRatesOnly,
    smbRate,
    cid,
  } = queryParameters || {};

  const rates: Rates = {};
  // add params only if value is truthy
  if (usePoints) rates.redeemPts = usePoints;
  if (token?.includes('HCAAA')) rates.aaaRate = true;
  if (token?.includes('HCARP')) rates.aarpRate = true;
  if (travelAgentRate) rates.travelAgent = true;
  if (token?.includes('HCSMR') || token?.includes('GDSSNR')) rates.seniorRate = true;
  if (token?.includes('GDSGOV')) rates.governmentRate = true;
  if (promoCode) rates.promotionCode = promoCode;
  if (promoCode !== 'false' && offerId) rates.offerId = offerId;
  if (groupCode) rates.groupCode = groupCode;
  if (corporateCode) rates.corporateCode = corporateCode;
  if (travelAgentId) rates.travelAgentId = travelAgentId;
  if (fromId && fromId.length <= 20) rates.fromId = fromId;
  if (smbRate) rates.smbRate = smbRate;

  const additionalQS: { [key: string]: string } = {};

  // add params only if value is truthy
  if (requestedRatesOnly) additionalQS.requestedRatesOnly = 'true';
  if (employeeRate) additionalQS.employeeRate = 'true';
  if (ownerVIPRate) additionalQS.ownerVIPRate = 'true';
  if (ownerHGVRate) additionalQS.ownerHGVRate = 'true';
  if (friendsAndFamilyRate) additionalQS.friendsAndFamilyRate = 'true';
  if (corporateCode !== 'false' && pnd) additionalQS.altCorporateId = pnd;
  if (specPlan && specPlan.length) additionalQS.spec_plan = specPlan.join(',');
  if (specialRateTokens && specialRateTokens.length) {
    additionalQS.specialRateTokens = specialRateTokens.join(',');
    additionalQS.srpName = specialRateTokens.join(',');
  }
  if (currencyCode) additionalQS.displayCurrency = currencyCode;
  if (cid) additionalQS.cid = cid;
  const page =
    datesFlex ||
    availStatus === 'NOT_AVAILABLE' ||
    availStatus === 'MAY_BE_AVAILABLE' ||
    availStatus === 'NOT_OPEN' ||
    usePointsExplorer
      ? 'flexibledates'
      : undefined;
  if (page) {
    additionalQS.flexibleDates = 'true';
  }

  const roomInfo: CBPRoom[] = rooms?.map((rm) => ({
    adults: rm.adultAges as Adult[],
    children: rm.childAges as Child[],
  }));

  return { arrivalDate, departureDate, additionalQS, rates, roomInfo, page };
};
type GenerateBookURLInputType = {
  availStatus?: AvailStatus | null;
  host: string;
  ctyhocn: string;
  isDreams: boolean;
  queryParameters?: QueryParameters;
  shouldUsePoints?: boolean;
  currencyCode: string;
  preOpenDate?: string | null;
  hasConnectingRooms?: boolean;
  isPointsExplorer: boolean;
  lang: MapLanguage;
  shouldExcludeSMBRateParameter?: boolean;
};
type GenerateBookURLInput = (params: GenerateBookURLInputType) => string;
export const generateCTAFunnelUrl: GenerateBookURLInput = ({
  availStatus = 'NOT_AVAILABLE' as AvailStatus,
  host,
  ctyhocn,
  queryParameters,
  currencyCode,
  shouldUsePoints,
  preOpenDate,
  hasConnectingRooms,
  isPointsExplorer,
  lang,
  isDreams = false,
}): string => {
  const { arrivalDate, departureDate, additionalQS, rates, roomInfo, page } = isDreams
    ? getAdditionalMarketingParams(currencyCode, shouldUsePoints, queryParameters)
    : queryParameters
    ? getRatesRoomInfoAdditionalQS(
        availStatus,
        queryParameters,
        currencyCode,
        isPointsExplorer,
        shouldUsePoints
      )
    : {
        arrivalDate: undefined,
        departureDate: undefined,
        additionalQS: undefined,
        rates: undefined,
        roomInfo: undefined,
        page: undefined,
      };

  const dates =
    preOpenDate && availStatus === 'NOT_OPEN'
      ? { arrivalDate: parseISO(preOpenDate), departureDate: addDays(parseISO(preOpenDate), 1) }
      : arrivalDate && departureDate
      ? {
          arrivalDate: parseISO(arrivalDate),
          departureDate: parseISO(departureDate),
        }
      : undefined;

  const adjoiningRoomStay = hasConnectingRooms || queryParameters?.adjoiningRoomStay;
  return bookUriBuilder({
    baseUrl: `https://${host}`,
    locale: lang || 'en',
    page,
    urlParams: {
      ctyhocn,
      rates: rates as BookRates,
      additionalQS: {
        ...additionalQS,
      },
      rooms: roomInfo,
      dates,
      adjoiningRoomStay,
    },
  });
};
