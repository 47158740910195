import type { NormalizedToggles } from '../constants';
import type { FeatureToggles_FeatureConfigsQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { ConfigRuleEngine } from '@dx-shared/rule-engine';
import type { ConfigRule } from '@dx-shared/rule-engine';

export const normalizedToggles = (featureToggleAndConfigs?: FeatureToggles_FeatureConfigsQuery) => {
  const toggles = featureToggleAndConfigs?.featureToggles.reduce((toggles, toggle) => {
    const featureConfig = featureToggleAndConfigs?.featureConfigs.find(
      (configItem) => configItem.name === toggle.name
    );
    toggles[toggle.name] = {
      enabled: toggle.enabled,
      config: featureConfig ? featureConfig.config : null,
    };
    return toggles;
  }, {} as NormalizedToggles);
  return toggles;
};

export const normalizedConfigRule = (
  configToggles?: FeatureToggles_FeatureConfigsQuery,
  facts?: { [key: string]: string }
) => {
  return configToggles?.featureConfigs.reduce((rules, rule) => {
    if (rule.name.startsWith('config-rule') && facts) {
      const configRule = new ConfigRuleEngine(rule as ConfigRule);
      rules[rule.name] = {
        enabled: true,
        config: rule.config,
        configFromRule: configRule.run(facts).config,
      };
    }
    return rules;
  }, {} as NormalizedToggles);
};
