/* eslint-disable import/no-cycle */
import { env } from '../../constants';
import { useTranslation } from 'next-i18next';
import type { MapLanguage } from '@dx-ui/framework-uri-builder';
import { searchUriBuilder } from '@dx-ui/framework-uri-builder';
import { useRouter } from 'next/router';
import type { ErrorKey, THelpfulLink } from '@dx-ui/osc-error-page';
import ErrorPage, { PhoneNumberLink, PhoneNumberSection } from '@dx-ui/osc-error-page';
import { useMetrics } from '../../hooks';

const OHW_BASE_URL = env.OHW_BASE_URL;

const Error = ({ statusCode }: { statusCode?: number }) => {
  const { t } = useTranslation(['error-text', 'osc-error-page']);
  const { locale } = useRouter();
  const metrics = useMetrics();

  const errorText = statusCode === 404 || statusCode === undefined ? '404' : 'technical';

  const HONORS_LOGIN = '/hilton-honors/login/';

  const translateProps = {
    searchUrl: new URL(
      searchUriBuilder({
        locale: locale as MapLanguage,
        path: 'find-hotels',
      }),
      OHW_BASE_URL
    ),
    accountUrl: `${OHW_BASE_URL}${locale}${HONORS_LOGIN}`,
    brandName: 'Hilton',
    brandUrl: 'https://www.hilton.com/en/',
    brandPhone: '1-800-446-6677',
  };

  const helpfulLinks = t('helpfulLinks', { returnObjects: true, ...translateProps });
  const contactInfo = t('contactInfo', { returnObjects: true, ...translateProps });

  const metricsProps = {
    country: '',
    state: '',
    city: '',
    category: '',
    brandCode: '',
    errorCode: statusCode || -999,
    errorName: '', //HACK: HAVE TO DISABLE FOR NOW SINCE IN STATIC PAGE WE CANT GET ERROR NAME.
  };
  return (
    <div className="flex flex-col px-8 py-10 md:p-20 lg:h-screen lg:items-center lg:justify-center">
      <ErrorPage
        helpfulLinks={helpfulLinks as THelpfulLink[]}
        error={errorText as ErrorKey}
        metrics={metrics}
        metricsProps={metricsProps}
      >
        {statusCode !== 404 ? (
          <div className="lg:divide-border flex flex-col items-center lg:flex-row lg:divide-x rtl:lg:divide-x-reverse">
            <ContactInfo {...contactInfo.brand} />
            <ContactInfo {...contactInfo.honors} />
          </div>
        ) : null}
      </ErrorPage>
    </div>
  );
};

type IContactInfo = {
  title: string;
  showIcon: boolean;
  numbers: INumber[];
  phoneText: string;
};

type INumber = {
  label: string;
  phone: string;
};

const ContactInfo = ({ title, numbers, showIcon }: IContactInfo) => (
  <div className="flex py-4 lg:px-12">
    <PhoneNumberSection showIcon={showIcon} title={title}>
      {numbers?.map((number) => (
        <PhoneNumberLink
          hint={number.label}
          key={number.label}
          label={number.phone}
          url={`tel:${number.phone}`}
        />
      ))}
    </PhoneNumberSection>
  </div>
);

export { Error };
