import { useMemo } from 'react';
import { useWrappedRouter } from './use-wrapped-router';
import { getBoundsAroundCoords } from '../utils/get-bounds-around-coords';
import { useAirportsQuery } from '@dx-ui/queries-dx-shop-search-ui';
import type { NextRouter } from 'next/router';
import type { GeocodePlaceType } from '@dx-ui/gql-types';
import { useMediaQuery } from 'usehooks-ts';

const geocodeTypeDistanceMap: { [key in GeocodePlaceType]?: Record<string, number> } =
  Object.freeze({
    property: { desktop: 40, subDesktop: 25 },
    address: { desktop: 40, subDesktop: 25 },
    airport: { desktop: 40, subDesktop: 25 },
    pointOfInterest: { desktop: 40, subDesktop: 25 },
  });

const getLocationUri = (router: NextRouter, shouldCheckForLocationPage: boolean) => {
  if (!shouldCheckForLocationPage || !router.isReady) {
    return '';
  }
  const { slug } = router.query;
  return Array.isArray(slug) ? `/locations/${slug.join('/')}/` : '';
};

export const useCoordsFromParams = () => {
  const { safeQueryParams: queryParameters, router } = useWrappedRouter();
  const isSubDesktop = useMediaQuery('(max-width: 1023px)');

  const geocodeType = queryParameters?.geocodeType || '';

  const paramCoords = queryParameters?.coords;
  const shouldCheckForLocationPage = geocodeType === 'airport';
  const { data: airportsData } = useAirportsQuery(
    {
      language: router?.locale ?? 'en',
      hasLocationPage: true,
      locationPageUri: getLocationUri(router, shouldCheckForLocationPage),
    },
    { enabled: !!router.isReady && !!shouldCheckForLocationPage }
  );

  const shouldIgnoreParamCoords =
    (shouldCheckForLocationPage && !airportsData) ||
    (Array.isArray(airportsData?.airports) && airportsData.airports.length > 0) ||
    !Object.hasOwnProperty.call(geocodeTypeDistanceMap, geocodeType);

  const result = useMemo(() => {
    if (geocodeType && paramCoords && typeof window !== 'undefined' && !shouldIgnoreParamCoords) {
      const { desktop, subDesktop } = geocodeTypeDistanceMap[geocodeType] ?? {};
      const miles = (isSubDesktop ? subDesktop : desktop) ?? 40;
      return {
        boundsFromParamCoords: getBoundsAroundCoords(paramCoords, miles),
        shouldUseParamCoords: true,
      };
    }
    return { boundsFromParamCoords: null, shouldUseParamCoords: false };
  }, [geocodeType, isSubDesktop, paramCoords, shouldIgnoreParamCoords]);

  return result;
};
