import { useFeatureToggles_FeatureConfigsQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useMemo } from 'react';
import type { Toggle } from '../constants';
import { FEATURE_TOGGLES } from '../constants';
import { normalizedConfigRule, normalizedToggles } from '../utils';

export const useFeatureToggleConfig = (
  toggleName: string,
  facts?: { [key: string]: string }
): Toggle => {
  const { data, isLoading } = useFeatureToggles_FeatureConfigsQuery({ names: FEATURE_TOGGLES });
  const featureToggles = useMemo(() => {
    if (isLoading) {
      return { enabled: false, config: {} };
    }
    const togglesFromToggles = normalizedToggles(data);
    const togglesFromRule = normalizedConfigRule(data, facts);
    if (togglesFromToggles || togglesFromRule) {
      return { ...togglesFromToggles, ...togglesFromRule };
    }
  }, [data, facts, isLoading]);

  const toggleKey = toggleName as keyof typeof featureToggles;

  if (featureToggles && toggleName in featureToggles) return featureToggles[toggleKey];
  else return { enabled: false, config: {}, ...(facts ? { configFromRule: {} } : {}) };
};
