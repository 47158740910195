import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import { logError } from '@dx-ui/framework-logger';

/**
 * Performs the tracking calls to Adobe Tag Manager.
 * Pulled from https://github.com/nfl/react-metrics/blob/master/examples/vendors/AdobeTagManager.js
 * @module AdobeTagManager
 * @class
 * @internal
 */
export default class AdobeTagManager {
  constructor(options = {}) {
    this.options = options;
    this.digitalData = options.digitalData || {};
    this.pageName = '';
    this.name = 'Adobe Tag Manager';
    //HACK: - Will reliably turn on onmiture debugging vs (_satellite.setDebug(true))
    if (!this.options.server) {
      localStorage.setItem('sdsat_debug', this.options.debug);
    }
  }

  /**
   *
   * @method setEventInfo
   * @param {String} eventName(s)
   * @internal
   */
  setEventInfo(...args) {
    this._addEventInfo(...args);
  }

  /**
   *
   * @method track
   * @param {String} eventName
   * @param {Object | null} params
   * @returns {Promise}
   * @internal
   */
  track(eventName, params, shouldAddEvent) {
    if (shouldAddEvent) {
      this._addEventInfo(eventName);
    }
    if (!this.options.server) {
      return new Promise((resolve) => {
        this._load()
          .then((satellite) => {
            this._satellite = this._satellite || satellite;
            this._track(eventName, params);
            resolve({
              eventName,
              params,
            });
          })
          .catch(() => {
            const error = new Error('Omniture: Failed to load seed file');
            logError('AdobeTagManager.track._load.seedScript.onerror', error);
            resolve(undefined);
          });
      });
    } else {
      return false;
    }
  }

  /**
   *
   * @method _track
   * @param {String} eventName
   * @param {Object} params
   * @protected
   */
  _track(eventName, params) {
    const data = isEmpty(params) ? this.digitalData : params;
    window.digitalData = data;
    if (this._satellite) {
      this._satellite.track(eventName);
    }
  }

  /**
   *
   * @method _load
   * @protected
   */
  _load() {
    this._cleanAndSetData();
    return (
      this._promise ||
      (this._promise = new Promise((resolve) => {
        if (window._satellite && window._satellite.track) {
          resolve(window._satellite);
        } else {
          const seedScript =
            document.querySelector('[data-name="launch"]') || document.createElement('script');

          seedScript.onload = () => {
            resolve(window._satellite);
          };

          seedScript.onerror = () => {
            logError(
              'AdobeTagManager._load.seedScript.onerror',
              new Error('Adobe analytics script failed to load seed file')
            );
            resolve(undefined);
          };

          seedScript.src = this.options.seedFile;
          seedScript.setAttribute('async', 'true');
          document.head.appendChild(seedScript);
        }
      }))
    );
  }

  _addEventInfo(names) {
    names = Array.isArray(names) ? names : [names];
    const events = names.map((name) => ({ eventInfo: { eventAction: name } }));
    this.digitalData.event.push(...events);
  }

  /**
   *
   * @method _resetPage
   * @public
   */
  _resetEvents() {
    this.digitalData.event = [];
  }

  /**
   *
   * @method _set
   * @public
   */
  _set(path, value) {
    set(this.digitalData, path, value);
  }

  /**
   *
   * @method _unset
   * @public
   */
  _unset(path, _value) {
    unset(this.digitalData, path);
  }

  /**
   * Nov 7, 2018: the following fields are what Hilton's custom Adobe Launch script will expect.
   * Check if the required fields exist and initialize them if they do not.
   * @method _cleanAndSetData
   * @private
   */
  _cleanAndSetData() {
    const propertySearchLocationPath = 'page.attributes.propertySearchLocation';
    const propertySearchLocation = get(this.digitalData, propertySearchLocationPath);
    if (propertySearchLocation === undefined) {
      this._set(propertySearchLocationPath, '');
    }

    const propertySearchDateInfoPath = 'page.attributes.propertySearchDateInfo';
    const propertySearchDateInfo = get(this.digitalData, propertySearchDateInfoPath);
    if (propertySearchDateInfo === undefined) {
      this._set(propertySearchDateInfoPath, '00:00:00:00');
    }

    const productIDPath = 'product[0].productInfo.productID';
    const productID = get(this.digitalData, productIDPath);
    if (productID === undefined) {
      this._set('product', [{ productInfo: { productId: '' } }]);
    }

    window.digitalData = this.digitalData;
  }
}
