import type { ActiveFiltersState } from '../../components/filters/filter.constants';

export type SessionState = {
  selectedCurrency?: string | null;
  sortType?: string;
  activeFilterState?: Partial<ActiveFiltersState> | undefined;
  shouldUsePoints?: boolean;
  showAvailableHotels?: boolean;
};
export const persistSessionState = ({
  activeFiltersState,
  selectedCurrency,
  sortType,
  shouldUsePoints,
}: {
  activeFiltersState?: Partial<ActiveFiltersState>;
  selectedCurrency?: string | null;
  sortType?: string | null;
  shouldUsePoints?: boolean;
}) => {
  const newSessionState: SessionState = {};
  if (selectedCurrency !== undefined) {
    if (selectedCurrency) newSessionState.selectedCurrency = selectedCurrency;
  }

  if (sortType) newSessionState.sortType = sortType;

  if (activeFiltersState) {
    newSessionState.activeFilterState = activeFiltersState;
  }

  if (shouldUsePoints !== undefined) {
    newSessionState.shouldUsePoints = shouldUsePoints;
  }

  if (Object.keys(newSessionState).length)
    sessionStorage.setItem('session-state', JSON.stringify(newSessionState));
};
