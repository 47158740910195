import type {
  ShopMultiPropAvailPointsQuery,
  ShopMultiPropAvailQuery,
} from '@dx-ui/queries-dx-shop-search-ui';
import type { HotelType } from '../providers/app-provider/app-provider.types';
import { getCMAPrice } from './get-cma-price';
import type { ConversionCurrencyOptions } from '@dx-ui/osc-currency-converter';
import { convert } from '@dx-ui/osc-currency-converter';

export type TransactionPropertiesDataInput = {
  currencyError?: boolean;
  datesFlex?: boolean;
  hotels: HotelType[];
  isDateSearch?: boolean;
  lengthOfStay?: number;
  pricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>;
  selectedCurrency?: string;
};

export type getTransactionPropertyDataInput = {
  currenciesError: boolean;
  currenciesMap: Map<string, ConversionCurrencyOptions>;
  hotel: HotelType;
  isDateSearch?: boolean;
  lengthOfStay?: number;
  selectedCurrency: string | undefined;
  priceInfo?: ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0] | null | undefined;
  shouldUsePoints?: boolean;
};
export function getTransactionPropertyData({
  currenciesError = false,
  currenciesMap,
  hotel,
  isDateSearch = false,
  lengthOfStay = 1,
  selectedCurrency,
  priceInfo,
  shouldUsePoints = false,
}: getTransactionPropertyDataInput): {
  propCode: string;
  srp: string;
  price: string;
  currency: string;
} {
  const propCode = hotel?.ctyhocn;
  let price = 'NA';
  let srp = 'NA';
  let rateAmt = 0;
  const currency = !selectedCurrency
    ? hotel.localization?.currencyCode || ''
    : selectedCurrency || '';

  if (shouldUsePoints) price = 'PTS';
  else if (priceInfo) {
    if (priceInfo?.statusCode === 0 && priceInfo?.summary?.status?.type === 'AVAILABLE') {
      const { totalRate } = getCMAPrice({
        cmaTotalPriceIndicator: priceInfo?.summary?.lowest?.cmaTotalPriceIndicator,
        rateAmount: priceInfo?.summary?.lowest?.rateAmount || 0,
        rateAmountFmt: priceInfo?.summary?.lowest?.rateAmountFmt || '',
        totalRate: priceInfo?.summary?.lowest?.amountAfterTax || 0,
        totalRateFmt: priceInfo?.summary?.lowest?.amountAfterTaxFmt || '',
        lengthOfStay,
      });
      price = `${Math.trunc(totalRate || 0)}`;
      rateAmt = totalRate || 0;
      srp = `${priceInfo?.summary?.lowest?.ratePlanCode}`;
    }
  } else if (!isDateSearch && hotel.leadRate?.lowest?.rateAmount && hotel?.display?.open) {
    price = `${Math.trunc(hotel.leadRate?.lowest?.rateAmount || 0)}`;
    rateAmt = hotel.leadRate?.lowest?.rateAmount || 0;
    srp = `${hotel.leadRate?.lowest?.ratePlanCode}`;
  }
  if (!['NA', 'PTS'].includes(price) && rateAmt > 0 && !currenciesError && currency !== 'USD') {
    const fromCurrency = currenciesMap?.get('USD') as ConversionCurrencyOptions;
    const toCurrency = currenciesMap?.get(currency) as ConversionCurrencyOptions;
    //convert currency
    const conversion = convert(rateAmt, fromCurrency, toCurrency, 0, 'trunc');
    price = `${conversion.price}`;
  }
  return { propCode, srp, price, currency };
}
