import Icon from '@dx-ui/osc-icon';
import cx from 'classnames';
import { Link } from '@dx-ui/osc-link';
import { useTranslation } from 'next-i18next';

type PhoneNumberLinkProps = React.ComponentProps<typeof Link> &
  ({ hint: string } | { hasStandardHint: boolean } | Record<never, never>);

function usePhoneNumberHint(props: PhoneNumberLinkProps): string {
  const { t } = useTranslation('osc-error-page');

  if ('hasStandardHint' in props && props.hasStandardHint === true) {
    return t('callMessage');
  }

  if ('hint' in props) {
    return props.hint;
  }

  return null as never;
}

function sanitizeLinkProps(props: PhoneNumberLinkProps): React.ComponentProps<typeof Link> {
  const linkProps: React.ComponentProps<typeof Link> = { ...props };

  if ('hasStandardHint' in linkProps) {
    delete linkProps.hasStandardHint;
  }

  return linkProps;
}

export function PhoneNumberLink(props: PhoneNumberLinkProps) {
  const { t } = useTranslation('osc-error-page');

  const hint = usePhoneNumberHint(props);

  return (
    <div key={props.label + props.url} className="flex flex-1 flex-col">
      <Link
        {...sanitizeLinkProps(props)}
        underline={false}
        className={cx(
          'text-primary hover:text-primary-alt whitespace-nowrap text-lg font-bold uppercase',
          props.className
        )}
      >
        <span className="sr-only">{t('phone')}</span>
        {props.label}
      </Link>
      {hint ? <p className="text-text-alt text-sm">{hint}</p> : null}
    </div>
  );
}

export function PhoneNumberSection({
  children,
  showIcon,
  title,
}: React.PropsWithChildren<{ showIcon?: boolean; title: string }>) {
  return (
    <div className="border-border mb-4 flex flex-1 flex-col justify-center first:border-0 lg:border-l-2 lg:px-16 lg:pt-2">
      <div className="flex flex-col self-stretch md:self-center">
        <div className="mb-2 flex self-start md:self-start">
          {showIcon && <Icon className="me-2" name="telephone" size="lg" variant="solid" />}
          <h3
            data-testid="contactInfoHeading"
            className="mb-1 inline-block text-nowrap align-bottom text-lg font-bold lg:text-xl"
          >
            {title}
          </h3>
        </div>
        <div className="flex flex-col self-start ps-1 sm:self-center sm:ps-0 md:self-start md:ps-1">
          {children}
        </div>
      </div>
    </div>
  );
}
