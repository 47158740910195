import type {
  CmsImageVariant,
  HotelImageVariant,
  HotelImageVariantSize,
} from '@dx-ui/queries-dx-shop-search-ui';
import getBreakpoint from './get-breakpoint';

type GetUrlParameter = (url: string, key: string) => string;

const getUrlParameter: GetUrlParameter = (url, key) => {
  const param = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${param}=([^&#]*)`);
  const [, value] = regex.exec(url) || [];

  return value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
};

/*
 * NHCSEARCH-3942
 * Return No Photo Available static image based on size passed
 */
export const getNoPhotoAvailable = (
  size: HotelImageVariantSize
): { altText: string; variants: HotelImageVariant[] }[] => {
  const altText = 'No photo available';
  const imageUrl = '/modules/assets/img/common/no-photo-available.png';
  let imageSize: HotelImageVariantSize;
  if (size === 'xs') {
    imageSize = 'xs';
  } else if (size === 'sm') {
    imageSize = 'sm';
  } else {
    imageSize = 'md';
  }
  return [
    {
      altText,
      variants: [{ size: imageSize, url: imageUrl }],
    },
  ];
};

const getOptimizedImages = (
  variants: Pick<HotelImageVariant, 'url' | 'size'>[],
  size: 'xs' | 'sm' | 'md'
) => {
  const variant = variants.find((variant) => variant.size === size);
  if (!variant?.url) return '';

  //if crop width/height exist, capture them
  const cw = parseInt(getUrlParameter(variant?.url, 'cw'));
  const ch = parseInt(getUrlParameter(variant?.url, 'ch'));

  //if we have no crop width/height return url as is
  if (!cw || !ch) return variant?.url;

  const [baseUrl] = variant?.url?.split('&rw') || '';

  //fallback is here for no JS view/server side render
  const width = (getBreakpoint(size, true) as number) || 375;

  return `${baseUrl}&rw=${width}&rh=${Math.round((width * ch) / cw)}`;
};

type CmsImageVariantType =
  | Pick<
      CmsImageVariant,
      'boxHeight' | 'boxWidth' | 'cdnLink' | 'height' | 'id' | 'left' | 'top' | 'size' | 'width'
    >
  | undefined;

export const getOptimizedCMSImages = (
  variants: CmsImageVariantType[],
  size: 'xs' | 'sm' | 'md'
): { height?: string; width?: string; url?: string } => {
  const variant = variants.find((variant) => variant?.size === size);
  if (!variant?.cdnLink) return {};

  //if crop width/height exist, capture them
  const cw = parseInt(getUrlParameter(variant?.cdnLink, 'cw'));
  const ch = parseInt(getUrlParameter(variant?.cdnLink, 'ch'));

  //Hardcoding as CMSImagesVariant width and height values are inconsistent
  const width = 220;
  const height = 90;
  //if we have no crop width/height return url as is
  if (!cw || !ch) return { height: `${height}px`, width: `${width}px`, url: variant?.cdnLink };

  const [baseUrl] = variant?.cdnLink?.split('&cw') || '';
  const appendDefaults = `&cw=0%&ch=0%&gravity=NorthWest&xposition=0&yposition=0&rw=${width}&rh=${height}`;

  const url = `${baseUrl}${appendDefaults}`;

  return { height: `${height}px`, width: `${width}px`, url };
};

export default getOptimizedImages;
