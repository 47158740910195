import { useEffect } from 'react';
import { useMetrics } from '../hooks/use-metrics';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { useLocation } from '@dx-ui/framework-location-provider';
import { useGeocodePageQuery } from '@dx-ui/queries-dx-shop-search-ui';
import type { LocationAbout, LocationPageInterlink } from '@dx-ui/queries-dx-shop-search-ui';
import { keepPreviousData } from '@tanstack/react-query';
import { useWrappedRouter } from './use-wrapped-router';

export function useDefaultLocationsPage() {
  const metrics = useMetrics();
  const { country } = useLocation();
  const { router } = useWrappedRouter();

  const { guestInfo, isLoading: isAuthLoading } = useAuth();

  const { data, isLoading } = useGeocodePageQuery(
    {
      language: router?.locale || 'en',
      pageType: 'DEFAULT',
      path: '/locations',
    },
    { placeholderData: keepPreviousData }
  );

  useEffect(() => {
    if (!isAuthLoading) {
      metrics.setDefaultLocationsPageData({
        honorsNumber: guestInfo?.hhonors?.hhonorsNumber || '',
        tier: guestInfo?.hhonors?.summary?.tierName || '',
        points: guestInfo?.hhonors?.summary?.totalPoints || 0,
        packages: (guestInfo?.hhonors?.packages as unknown as { packageName: string }[]) || [],
        country: country as string,
      });
      metrics.setPageData({ page: 'categoryPageView' });
    }
  }, [
    country,
    guestInfo?.hhonors?.hhonorsNumber,
    guestInfo?.hhonors?.packages,
    guestInfo?.hhonors?.summary?.tierName,
    guestInfo?.hhonors?.summary?.totalPoints,
    isAuthLoading,
    metrics,
  ]);

  return {
    aboutContentBlock: data?.geocodePage?.location?.about as LocationAbout | undefined,
    isGeoCodePageLoading: isLoading,
    pageInterLinks: data?.geocodePage?.location?.pageInterlinks as LocationPageInterlink[],
  };
}
