import { type HotelQuery } from '@dx-ui/queries-dx-shop-search-ui';

type HotelDisplay = NonNullable<HotelQuery['hotel']>['display'] | undefined | null;

export function isAffiliateHotel(display?: HotelDisplay) {
  return display?.treatments?.includes('affiliate') || false;
}

export function useIsAffiliateHotel(display?: HotelDisplay) {
  return isAffiliateHotel(display);
}
