import type { GetServerSidePropsContext } from 'next';
import type { Brand } from '@dx-ui/queries-dx-shop-search-ui';
import {
  generateFindHotelsUrl,
  generateOHWSearchZeroUrl,
  getUrlParamsString,
} from './search-zero-utils';

export type BrandType = Pick<
  Brand,
  'code' | 'name' | 'url' | 'canonicalSlug' | 'altSlugs' | 'isHotelBrand' | 'hasHotels'
>;

type BrandsInput = {
  brands?: BrandType[];
  brandName?: string;
};
type BrandDetailsInput = {
  brands?: BrandType[];
  brandName: string;
  query: GetServerSidePropsContext['query'];
  path: string;
  locale?: string;
};
export const getBrandFromQuery = (input: BrandsInput) => {
  let brand;
  if (input?.brandName) {
    // brand.altSlug  or brand.canonicalSlug or brand.name  or brand.code passed as brandName
    brand = input?.brands?.find(
      (brand) =>
        brand?.canonicalSlug?.toLowerCase() === input.brandName?.toLowerCase() ||
        brand.altSlugs?.some(
          (altSlug) =>
            altSlug.toLowerCase() === input?.brandName?.toLowerCase() ||
            brand?.name.toLowerCase() === input.brandName?.toLowerCase() ||
            brand?.code.toLowerCase() === input.brandName?.toLowerCase()
        )
    );
  }
  return {
    brandSlug: brand?.canonicalSlug || '',
    isHotelBrand: !!brand?.isHotelBrand,
    hasHotels: !!brand?.hasHotels,
  };
};

export const validateBrandSlug = (
  input: BrandDetailsInput
): { brandName: string; destinationUrl: string | null } => {
  const { brandSlug, isHotelBrand, hasHotels } = getBrandFromQuery({
    brands: input?.brands,
    brandName: input.brandName,
  });

  const destinationUrl = getDestinationUrl({
    query: input.query,
    locale: input.locale,
    resolvedUrl: input.path,
    canonicalSlug: isHotelBrand && hasHotels ? brandSlug : '',
    isHotelBrandAndHasHotels: isHotelBrand && hasHotels,
  });

  return {
    brandName: isHotelBrand ? brandSlug || '' : '',
    destinationUrl,
  };
};

export function generateSearchURLWithBrand(
  brandSlug: string,
  primarySlug: string | undefined,
  locale: string | undefined,
  path: string | undefined
) {
  const searchZeroParams = getUrlParamsString(locale, path);
  const addPrimarySlug = primarySlug ? `/${primarySlug}` : '';
  const addBrandSlug = brandSlug ? `/${brandSlug}` : '';
  return `/${locale}${addPrimarySlug}/search${addBrandSlug}/${searchZeroParams}`;
}
export const getDestinationUrl = ({
  query,
  resolvedUrl,
  locale,
  canonicalSlug = '',
  isHotelBrandAndHasHotels,
}: {
  query: GetServerSidePropsContext['query'];
  resolvedUrl: string;
  locale?: string;
  canonicalSlug?: string;
  isHotelBrandAndHasHotels?: boolean;
}) => {
  const primarySlug = (query?.primarySlug || '') as string;
  let destinationUrl = null;
  const isQueryEmpty = !query.query && !query.searchQuery;

  if (canonicalSlug !== query.brandName || !isHotelBrandAndHasHotels) {
    if (isQueryEmpty) {
      destinationUrl = generateFindHotelsUrl(
        primarySlug,
        locale,
        resolvedUrl,
        canonicalSlug,
        query.brandName as string
      );
    } else
      destinationUrl = generateSearchURLWithBrand(canonicalSlug, primarySlug, locale, resolvedUrl);
  } else if (isQueryEmpty) {
    destinationUrl = generateOHWSearchZeroUrl({
      locale,
      path: resolvedUrl,
      brandSlug: canonicalSlug,
      primarySlug,
      brandName: query.brandName || '',
    });
  }

  return destinationUrl;
};
