import { HotelSummaryOptionsDocument } from '@dx-ui/queries-dx-shop-search-ui';
import type { QueryClient } from '@tanstack/react-query';
import type {
  Geocode_HotelSummaryOptionsQuery,
  HotelSummaryOptionsQuery,
  HotelSummaryOptions_GeocodePageQuery,
} from '@dx-ui/queries-dx-shop-search-ui';
import type { HotelType } from '../../../providers/app-provider/app-provider.types';

export const getHashedHotelSummaryOptionsDataFromCache = (
  queryClient: QueryClient,
  cacheData: Record<string, HotelType>
) =>
  queryClient
    .getQueriesData<HotelSummaryOptionsQuery>({
      queryKey: [HotelSummaryOptionsDocument],
    })
    .reduce((hashMap, cacheEntry) => {
      const normalizedCacheEntry = cacheEntry?.[1]?.hotelSummaryOptions?.hotels.reduce(
        (normalizedData, cacheValue) => {
          if (cacheValue?.ctyhocn) normalizedData[cacheValue?.ctyhocn] = cacheValue;
          return normalizedData;
        },
        {} as Record<string, HotelType>
      );
      return { ...hashMap, ...normalizedCacheEntry };
    }, cacheData || ({} as Record<string, HotelType>));

export type THsoHotel = Record<
  string,
  NonNullable<HotelSummaryOptionsQuery['hotelSummaryOptions']>['hotels'][0]
>;
export type THotelSummaryOptionsData =
  | HotelSummaryOptionsQuery
  | Geocode_HotelSummaryOptionsQuery['geocode']
  | HotelSummaryOptions_GeocodePageQuery['geocodePage'];

export const hashHotelSummaryOptionsData = (
  hotelSummaryOptionsData: THotelSummaryOptionsData | undefined | null,
  cacheData: THsoHotel
) => {
  const isHotelSummaryOptionsQuery = (
    data: THotelSummaryOptionsData | undefined | null
  ): data is HotelSummaryOptionsQuery => data?.hotelSummaryOptions?.hotels !== undefined;
  //guard that HSO defined needed or tsc throws a call signature error on reduce
  if (isHotelSummaryOptionsQuery(hotelSummaryOptionsData)) {
    return (
      hotelSummaryOptionsData?.hotelSummaryOptions?.hotels?.reduce((accum, hotel) => {
        accum[hotel?.ctyhocn] = hotel;
        return accum;
      }, cacheData || ({} as THsoHotel)) || {}
    );
  }
  return {};
};
