//Agents
export const CHANGE_DEFAULT_SORT_WHEN_USING_POINTS = { agentId: 'a-tTCxboiLp1xh' };
export const WHY_PROPERTY = { agentId: 'a-jJfo0QQjRpXD' };
export const BRAND_FILTER_CATEGORIES_V2 = { agentId: 'a-NHNLF37qpGEx' };
export const REMOVE_FLEXIBLE_DATES = { agentId: 'a-88XVLKrnNI' }; // NHCSEARCH-5088
export const AMENITY_SECTION_RANKING = { agentId: 'a-jZIxq49KNw' }; //NHCSEARCH-5126
export const HIGHEST_AMENITIES_SUGGESTED_FILTERS = { agentId: 'a-GmE1urgBDm' }; //NHCSEARCH-5346
export const RESTYLE_DEFAUlT_LOCATIONS_CONTENT_BLOCKS = { agentId: 'a-y7krXYMt5GOH' }; //NHCSEARCH-5305
export const HDP_ROOMS = { agentId: 'a-7pnn1PRQAg' }; //NHCSEARCH-5624
export const COMPARE_ONBOARDING = { agentId: 'a-ibECGHj2abNq' }; //NHCSEARCH-5449
export const DISPLAY_NUM_OF_AVAILABLE_POINTS = { agentId: 'a-bq0xOMSYvb9X' }; // NHCSEARCH-5670
export const AMENITY_CATEGORIES_REORDER = { agentId: 'a-3je4ALXN9T' }; //NHCSEARCH-5196
export const FLEX_DATES_VERBIAGE = { agentId: 'a-d962rSWS6Y66' }; //NHCSEARCH-5363
export const VERTICAL_SPACE_SAVINGS = { agentId: 'a-xWbHOmL7nuGi' }; //NHCSEARCH-5451
export const AMEX_BANNER_DEFAULT_LOCATIONS = { agentId: 'a-geDi8LFS7fLd' }; //NHCSEARCH-5592
export const KEY_AMENITY_INFO_ON_HOTEL_CARD = { agentId: 'a-z3L3qY0iBD' }; // NHCSEARCH-5622
export const POI_AIRPORT_SHUTTLE_ON_HDP = { agentId: 'a-aDJ6cVqwTM' }; //NHCSEARCH-5621
export const SHOW_NUM_POINTS_IN_POINTS_MODAL = { agentId: 'a-GmtjHBoLfeiC' }; //NHCSEARCH-5746
export const AI_STAY_PLANNER_ENABLE_TRAFFIC_PERCENTAGE = { agentId: 'a-gl4LK18Ces' }; //NHCSEARCH-5423
export const MONTH_TABS_IN_DATES_MODAL = { agentId: 'a-AYQsBRl4Ifok' }; //NHCSEARCH-5716
export const POINTS_EXPLORER_DEFAULT_LOCATIONS = { agentId: 'a-HiGzFNktSFXT' }; //NHCSEARCH-5747

export const VARIANTS = {
  Default: 'a',
  VariantB: 'b',
  VariantC: 'c',
  VariantD: 'd',
};

export const GOALS = {
  FilterEngagement: 'g-filterengagement',
  LocationsPageLoad: 'g-Bnktxc1kfY',
  SearchResultsPageLoad: 'g-0JJz7s9mtH',
  suggestedFilterEngagement: 'g-9GiRp71nci',
  shopHonorsLogin: 'g-b5JxefXddp',
  ZeroResultsCount: 'g-zeroresultscount',

  //NHCSEARCH-4697 Brand Categories
  BrandsFilterApply: 'g-rBf6w9ia4l',
  BrandsFilterClick: 'g-iVmMvcnCEJ',

  //NHCSEARCH-4783 Default locations Hero and Shop Form Update
  DLFindAHotel: 'g-34ZXagz3zu',

  // NHCSEARCH-5028 Why Property (reused from 4679 Why Brand)
  whyBrandClicked: 'g-PxxunyVcGw',
  whyBrandTotalClicks: 'g-VmdFNCNK8X',

  //NHCSEARCH-5088 Remove Flexible dates
  myFlexibleDatesClick: 'g-lNDlYVzRu9',
  resetDates: 'g-gjmxUjWSsN',

  //NHCSEARCH-5126, NHCSEARCH-5196
  amenitiesFilterApplied: 'g-1xsItW1p6l',

  compareHotelsCheckboxSelected: 'g-tJK6XYgfzg',
  compareHotelsCTAClick: 'g-fLXN7qrWyE',
  compareHotelsToggleOn: 'g-6JoBhxn0qO',

  //NHCSEARCH-5305 - Restyle default location content blocks
  seoContentBlockLinkClicked: 'g-C0KoORMKNV',

  //NHCSEARCH-5306
  hdpRatesClick: 'g-FsogjdwAGF',
  hdpPaymentClick: 'g-sFRW2ocBEh',
  hdpRoomsClick: 'g-iHa1kCBy7q',

  //NHCSEARCH-5432  Filters CTAs order
  allFiltersEngagement: 'g-KdEcBRaC9Q',
  amenitiesFilterEngagement: 'g-IX8nArbk3U',
  priceFilterClick: 'g-FggLQuCasj',

  //NHCSEARCH-5451 Vertical Space Savings
  sortEngagement: 'g-yG33OlhDUQ',
  currencyEngagement: 'g-BgbSyTvidK',

  // NHCSEARCH-5592 - Amex banner on default locations
  amexModalOpen: 'g-ky7ZdRJrQ9',
  amexModalCTA: 'g-thBL8O15mq',
  amexBannerClick: 'g-MYxlENt8wX',

  //NHCSEARCH-5670 - Display number of points
  pointsLoginClick: 'g-CNXVkKbeyI',
  pointsModalOpen: 'g-ILeHaHnLUq',
  shopLoginClick: 'g-8j8IxGqRjg',
  pointsFilterApply: 'g-w1izXiWwzr',

  //NHCSEARCH-5747 - Points Explorer Default Locations
  planYourStayTabClick: 'g-IqOCw5CUJh',
  pricePlannerFindHotelClick: 'g-OwmMwCnL9D',
};
