import type { GeocodeAddress, GeocodePlaceType } from '@dx-ui/queries-dx-shop-search-ui';
import { useAppState } from '../providers/app-provider';
import { useGetRegions } from './use-get-regions';

type VisibleHotelsParams = {
  address: Pick<GeocodeAddress, 'country' | 'state'> | null | undefined;
  hasUserPanned: boolean;
  hasUserZoomed: boolean;
  isLoading?: boolean;
  matchName?: string;
  type: GeocodePlaceType | undefined;
};

export const useGetFilteredHotels = ({
  address,
  hasUserPanned,
  hasUserZoomed,
  isLoading,
  matchName,
  type,
}: VisibleHotelsParams) => {
  const { countryCodes } = useGetRegions({
    isTypeRegion: type === 'region',
    regionName: matchName?.toLowerCase() || '',
    isEnabled: type === 'region',
  });
  const { hotelsInBounds } = useAppState();

  const isCountryOrStateOrRegion = ['country', 'state', 'region'].includes(type || '');
  const visibleHotels =
    !isCountryOrStateOrRegion || hasUserPanned || hasUserZoomed
      ? Object.keys(hotelsInBounds)
      : Object.keys(hotelsInBounds).filter((ctyhocn) => {
          if (hotelsInBounds?.[ctyhocn]) {
            if (type === 'region') {
              return (
                type === 'region' &&
                countryCodes?.includes(hotelsInBounds[ctyhocn]?.address?.country ?? '')
              );
            }

            if (type === 'country') {
              return hotelsInBounds[ctyhocn]?.address?.country === address?.country;
            }

            if (type === 'state' && hotelsInBounds[ctyhocn]) {
              return hotelsInBounds[ctyhocn]?.address?.state === address?.state;
            }
            return true;
          }
          return false;
        });

  const findingHotels = isLoading;

  return {
    visibleHotels,
    findingHotels,
  };
};
