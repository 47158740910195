import type { HotelType } from '../providers/app-provider/app-provider.types';
import type {
  ShopMultiPropAvailPointsQuery,
  ShopMultiPropAvailQuery,
  HotelCoordinate,
} from '@dx-ui/queries-dx-shop-search-ui';
import type { TFunction } from 'i18next';
import { calculateCenterToHotelDistance } from './calculate-center-to-hotel-distance';
import type { GoogleCoordinate } from './get-bounds-nodes/get-bounds-nodes';

const SortDirection = {
  Asc: 1,
  Desc: -1,
};

type HotelCenterCoordinate = Pick<HotelCoordinate, 'latitude' | 'longitude'> | undefined;

const getDistanceFromCenter = (
  centerCoordinate: GoogleCoordinate | null,
  hotelCoordinate: HotelCenterCoordinate | null,
  language?: string
) => {
  const distanceUnit = language !== 'en' ? 'km' : 'mi';
  const center = { latitude: centerCoordinate?.lat || 0, longitude: centerCoordinate?.lng || 0 };
  const latitude = hotelCoordinate?.latitude || 0;
  const longitude = hotelCoordinate?.longitude || 0;
  const distance =
    (latitude &&
      longitude &&
      calculateCenterToHotelDistance({ latitude, longitude }, center, distanceUnit, 2)) ||
    0;

  return {
    distance,
  };
};

export const getDistanceFromCenterFmt = (
  t: TFunction<['hotel-card']>,
  centerCoordinate: GoogleCoordinate | null,
  hotelCoordinate: HotelCenterCoordinate | null,
  language?: string
) => {
  const distanceUnit = language !== 'en' ? 'km' : 'mi';
  const { distance } = getDistanceFromCenter(centerCoordinate, hotelCoordinate, language);
  const distanceFmt = `${new Intl.NumberFormat(language, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(distance)} ${distanceUnit === 'mi' ? t('miles') : t('kilometers')}`;

  return {
    distanceFmt,
    distance,
  };
};

const getDistance = (
  centerCoordinate: GoogleCoordinate | null,
  hotelCoordinate?: HotelCenterCoordinate | null
) => {
  const { distance } = getDistanceFromCenter(centerCoordinate, hotelCoordinate);
  return distance;
};
const distanceSortFn = (a: HotelType, b: HotelType, centerCoordinate: GoogleCoordinate | null) => {
  const aDistance = getDistance(centerCoordinate, a.localization?.coordinate);
  const bDistance = getDistance(centerCoordinate, b.localization?.coordinate);
  const distanceSortResult = aDistance - bDistance;

  // If distances are different, then we're done sorting
  if (distanceSortResult !== 0) return distanceSortResult;

  // If distances are the same, then sort by name
  const aName = a.name || '';
  const bName = b.name || '';
  return aName.localeCompare(bName);
};

const ratingSortFn = (
  a: HotelType,
  b: HotelType,
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null
) => {
  const aRating =
    a.tripAdvisorLocationSummary?.rating ??
    (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
  const bRating =
    b.tripAdvisorLocationSummary?.rating ??
    (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);

  const ratingSortResult = (aRating - bRating) * direction;

  // If prices are different, then we're done sorting
  if (ratingSortResult !== 0) return ratingSortResult;

  // When prices are the same, then sort by distance
  return distanceSortFn(a, b, centerCoordinate);
};

const priceSortFn = (
  a: HotelType,
  b: HotelType,
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null,
  mpaPricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>,
  showAmountAfterTax?: boolean
) => {
  let aPrice;
  let bPrice;
  // if we are using shopMultiPropAvail data then use different field for price sort
  if (mpaPricing) {
    const aRateAmount = showAmountAfterTax
      ? mpaPricing[a.ctyhocn]?.summary?.lowest?.amountAfterTax
      : mpaPricing[a.ctyhocn]?.summary?.lowest?.rateAmount;
    const bRateAmount = showAmountAfterTax
      ? mpaPricing[b.ctyhocn]?.summary?.lowest?.amountAfterTax
      : mpaPricing[b.ctyhocn]?.summary?.lowest?.rateAmount;
    aPrice = aRateAmount ?? (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
    bPrice = bRateAmount ?? (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
  }
  // else use lead rates from hotel object
  else {
    aPrice =
      a.leadRate?.lowest?.rateAmount ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
    bPrice =
      b.leadRate?.lowest?.rateAmount ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
  }

  const priceSortResult = (aPrice - bPrice) * direction;

  // If prices are different, then we're done sorting
  if (priceSortResult !== 0) return priceSortResult;

  // When prices are the same, then sort by distance
  return distanceSortFn(a, b, centerCoordinate);
};

const pointsSortFn = (
  a: HotelType,
  b: HotelType,
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null,
  mpaPricing?: Record<string, ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0]>
) => {
  let aPoints;
  let bPoints;
  if (mpaPricing) {
    aPoints =
      mpaPricing?.[a.ctyhocn]?.summary?.hhonors?.dailyRmPointsRate ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
    bPoints =
      mpaPricing?.[b.ctyhocn]?.summary?.hhonors?.dailyRmPointsRate ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
  }
  // else use lead rates from hotel object
  else {
    aPoints =
      a.leadRate?.hhonors?.lead?.dailyRmPointsRate ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
    bPoints =
      b.leadRate?.hhonors?.lead?.dailyRmPointsRate ??
      (direction === SortDirection.Desc ? -1 : Number.MAX_SAFE_INTEGER);
  }

  const pointsSortResult = (aPoints - bPoints) * direction;

  // If points are different, then we're done sorting
  if (pointsSortResult !== 0) return pointsSortResult;

  // otherwise fallback to distance sort
  return distanceSortFn(a, b, centerCoordinate);
};

const sortByBrand = (
  brandCode: string | undefined,
  hotels: HotelType[],
  centerCoordinate: GoogleCoordinate | null
) => {
  const hotelsSortedByDistance = sortByDistance(hotels, centerCoordinate);
  const brandedCtyhocns = hotelsSortedByDistance.filter((hotel) => hotel.brandCode === brandCode);
  const otherCtyhocns = hotelsSortedByDistance.filter((hotel) => hotel.brandCode !== brandCode);

  // merge results
  return [...brandedCtyhocns, ...otherCtyhocns];
};

const sortByDistance = (hotels: HotelType[], centerCoordinate: GoogleCoordinate | null) =>
  hotels.sort((a, b) => distanceSortFn(a, b, centerCoordinate));

const sortByPoints = (
  hotels: HotelType[],
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null,
  mpaPricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>
) => hotels.sort((a, b) => pointsSortFn(a, b, direction, centerCoordinate, mpaPricing));

const sortByPrice = (
  hotels: HotelType[],
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null,
  mpaPricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>,
  showAmountAfterTax?: boolean
) =>
  hotels.sort((a, b) =>
    priceSortFn(a, b, direction, centerCoordinate, mpaPricing, showAmountAfterTax)
  );

const sortByRating = (
  hotels: HotelType[],
  direction: ValuesOf<typeof SortDirection>,
  centerCoordinate: GoogleCoordinate | null
) => hotels.sort((a, b) => ratingSortFn(a, b, direction, centerCoordinate));

export const sortHotels = (
  sortType: string | null,
  brandCode: string | undefined,
  hotels: HotelType[],
  centerCoordinate: GoogleCoordinate | null,
  mpaPricing?: Record<string, ShopMultiPropAvailQuery['shopMultiPropAvail'][0]>,
  showAmountAfterTax?: boolean
) => {
  if (!hotels?.length) return [];
  switch (sortType) {
    case 'BRAND':
      return sortByBrand(brandCode, hotels, centerCoordinate);
    case 'DISTANCE':
      return sortByDistance(hotels, centerCoordinate);
    case 'POINTS_ASCENDING':
      return sortByPoints(hotels, SortDirection.Asc, centerCoordinate, mpaPricing);
    case 'POINTS_DESCENDING':
      return sortByPoints(hotels, SortDirection.Desc, centerCoordinate, mpaPricing);
    case 'PRICE_ASCENDING':
      return sortByPrice(
        hotels,
        SortDirection.Asc,
        centerCoordinate,
        mpaPricing,
        showAmountAfterTax
      );
    case 'PRICE_DESCENDING':
      return sortByPrice(
        hotels,
        SortDirection.Desc,
        centerCoordinate,
        mpaPricing,
        showAmountAfterTax
      );
    case 'RATINGS_ASCENDING':
      return sortByRating(hotels, SortDirection.Asc, centerCoordinate);
    case 'RATINGS_DESCENDING':
      return sortByRating(hotels, SortDirection.Desc, centerCoordinate);
    default:
      return hotels;
  }
};
