import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { returnInitialBounds } from '../components/map/map.utils';
import { useHotelSummaryOptions_GeocodePageQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useCoordsFromParams } from './use-coords-from-params';
import { keepPreviousData } from '@tanstack/react-query';
import { useLocation } from '@dx-ui/framework-location-provider';

export const useGetDreamPageData = (isEnabled = true) => {
  const router = useRouter();
  const contentParam = Array.isArray(router.query?.content)
    ? router.query?.content[0]
    : router.query?.content;
  const language = contentParam || router.locale || 'en';
  const locationPath = useMemo(() => {
    const routerPath = router.asPath?.split('?')[0];
    if (routerPath) return routerPath.replace(/\/$/, '').replace(/^\//, '');
    return '';
  }, [router.asPath]);

  const { country: guestLocationCountry, isLoading: isGuestLocationLoading } = useLocation();
  const { data, isLoading, isFetching } = useHotelSummaryOptions_GeocodePageQuery(
    {
      path: locationPath,
      language,
      queryLimit: 20,
      currencyCode: 'USD',
      titleFormat: 'md',
      input: { guestLocationCountry: guestLocationCountry || 'US' },
    },
    {
      enabled: isEnabled && !isGuestLocationLoading,
      placeholderData: keepPreviousData,
    }
  );
  const initHotelCount = data?.geocodePage?.ctyhocnList?.hotelList?.length;
  const { boundsFromParamCoords } = useCoordsFromParams();

  const getHSOData = useMemo(() => {
    if (!isLoading && data) {
      const pageData = data?.geocodePage;
      const englishPageData = data?.geocodePageEn;
      const bounds = returnInitialBounds(
        pageData?.hotelSummaryOptions?.bounds,
        pageData?.match?.geometry?.bounds,
        boundsFromParamCoords
      );
      const isGlobalPage = !!pageData?.location?.globalBounds;
      //This is a bandaid. For macro pages w/ global bounds we have to throw away match name for now since the match name it returns will not lead to a valid OHW page.
      const matchName = !isGlobalPage ? pageData?.match?.name || '' : '';
      const regionMatchName = matchName ? englishPageData?.match?.name : '';

      return {
        bounds,
        data: pageData,
        isLoading,
        locationMatchName: matchName,
        regionMatchName,
      };
    }
  }, [boundsFromParamCoords, data, isLoading]);

  return {
    ...getHSOData,
    isLoading: getHSOData?.isLoading ?? false,
    locationPath,
    isFetching,
    initHotelCount,
  };
};
