import { useGeocode_HotelSummaryOptionsQuery } from '@dx-ui/queries-dx-shop-search-ui';
import { useMemo } from 'react';
import { useWrappedRouter } from './use-wrapped-router';
import { returnInitialBounds } from '../components/map/map.utils';
import { useIsClient } from 'usehooks-ts';
import { MAX_PROPERTIES_PER_LIST_VIEW_PAGE } from '../constants';
import { keepPreviousData } from '@tanstack/react-query';

type GeocodeHSOProps = {
  address?: string;
  placeId: string | null | undefined;
  limit?: number;
};
export const useGeocodeHSO = (props: GeocodeHSOProps) => {
  const { safeQueryParams, router } = useWrappedRouter();
  const { address, placeId } = props;
  const isClient = useIsClient();

  //With Shallow routing, placeId is not reset and caused to display
  const { newAddress, newPlaceId } = useMemo(() => {
    let newAddress = address || '';
    let newPlaceId = placeId || null;
    if (safeQueryParams?.query && safeQueryParams?.query !== address) {
      newAddress = decodeURIComponent(
        safeQueryParams?.query || (safeQueryParams?.searchQuery as string)
      );
      newPlaceId = null;
    }

    newPlaceId =
      safeQueryParams?.placeId && safeQueryParams?.placeId !== 'null'
        ? decodeURIComponent(safeQueryParams?.placeId)
        : null;

    return { newAddress, newPlaceId };
  }, [
    address,
    placeId,
    safeQueryParams?.query,
    safeQueryParams?.placeId,
    safeQueryParams?.searchQuery,
  ]);

  const { data, isLoading, isFetching } = useGeocode_HotelSummaryOptionsQuery(
    {
      address: newAddress,
      language: router?.locale || 'en',
      placeId: newPlaceId,
      queryLimit: props?.limit || MAX_PROPERTIES_PER_LIST_VIEW_PAGE,
    },
    {
      enabled: isClient,
      placeholderData: keepPreviousData,
    }
  );

  const initBounds = returnInitialBounds(
    data?.geocode?.hotelSummaryOptions?.bounds,
    data?.geocode?.match?.geometry?.bounds
  );

  return {
    data,
    initBounds,
    newAddress,
    newPlaceId,
    isLoading,
    isFetching,
  };
};
