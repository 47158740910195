import { useAppState } from '../providers/app-provider';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

export const useMapListToggle = () => {
  const { t } = useTranslation('search-page');
  const isSlimView = useMediaQuery('(max-width: 767px)');
  const { isListVisible } = useAppState();

  const isDesktopListVisible = isListVisible && !isSlimView;
  const isDesktopMapView = !isListVisible && !isSlimView;
  const isSlimListView = isListVisible && isSlimView;
  const isSlimMapView = !isListVisible && isSlimView;

  const labelAndIcon: { iconName?: string; label: string } | undefined = useMemo(() => {
    switch (true) {
      case isDesktopMapView:
        return { iconName: 'arrowhead-right', label: t('showListView') };
      case isDesktopListVisible:
        return { iconName: 'arrowhead-left', label: t('hideList') };
      case isSlimListView:
        return { label: t('showMap') };
      case isSlimMapView:
        return { label: t('showList') };
      default:
        break;
    }
  }, [isDesktopListVisible, isDesktopMapView, isSlimListView, isSlimMapView, t]);

  return {
    ...labelAndIcon,
    isSlimView,
    isDesktopListVisible,
    isDesktopMapView,
  };
};
